.container {
  display: flex;
  width: 100%;
  padding: 2rem;
  .blog_left {
    width: 75%;
    .blog_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;
      .title {
        font-weight: 600;
      }
      .header_filters {
        display: flex;
        align-items: center;
        .filter_text {
          margin-left: 1rem;
        }
        input {
          transition-property: width;
          transition-duration: 1s;
        }
        input:focus,
        input:active {
          width: 30vw;
        }
        .filter {
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .blog_right {
    flex: 1;
    border: 1px solid #d3d3d3;
  }
}

.selectedMenuItem {
  background-color: #e5e5e5 !important;
}
