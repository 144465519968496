.footer_container {
  height: auto;
  padding: 1rem;
  background-color: #fff;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  bottom: 0;
  box-shadow: 0 -1px 6px -1px rgba(0, 0, 0, 0.1);
  text-align: center; /* Ensure text is centered */
}

.footer_container > * {
  text-align: center;
  font-size: 1rem;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .footer_container {
    padding: 0.5rem; /* Adjust padding for smaller screens */
  }

  .footer_container > * {
    font-size: 0.9rem; /* Slightly smaller font on mobile */
  }
}

@media (max-width: 480px) {
  .footer_container {
    flex-direction: column; /* Stack content vertically on very small screens */
    padding: 0.75rem;
  }

  .footer_container > * {
    font-size: 0.85rem; /* Reduce font size for small mobile screens */
  }
}
