.header_container {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  min-height: 5rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.header_container > .title {
  font-size: 2rem;
  font-weight: 700;
}

.logo{
  width: 5rem;
}

.header_container a {
  text-decoration: none;
  text-transform: capitalize;
  color: #000;
  font-size: large;
  font-weight: 600;
}

.header_nav_links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header_nav_links > * {
  margin-right: 1rem;
}

.header_nav_links > :last-of-type {
  margin-right: 0;
}

/* Hide navigation links on mobile screens */
@media (max-width: 768px) {
  .header_nav_links {
    display: none;
  }

  .menuButton {
    display: inline-block;
  }
}

@media (min-width: 769px) {
  .menuButton {
    display: none !important; /* Hide menu button on desktop */
  }
}

/* Drawer styles */
.drawerList {
  width: 250px;
  padding: 1rem;
}

.drawerList a {
  font-size: 1.2rem;
  font-weight: 600;
  color: #000;
  text-transform: capitalize;
  text-decoration: none;
}
