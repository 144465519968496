.container {
  display: flex;
  align-items: center;
  padding: 2rem 1rem;
  .content {
    width: 60%;
    padding-right: 2rem;
    span {
      color: #b6b6b6;
    }
    .hashtags{
        display: flex;
        gap: 10px;
    }
  }
  img {
    width: 12rem;
    height: 7rem;
    object-fit: cover;
  }
}
