.contact_container {
    padding: 2rem;
    max-width: 800px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);
  }
  
  .title {
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .description {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #666;
  }
  
  .contact_info {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .info_item {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .icon {
    color: #333;
    font-size: 2rem;
  }
  
  a {
    text-decoration: none;
    color: #0066cc;
    transition: color 0.3s ease;
  }
  
  a:hover {
    color: #004c99;
  }
  
  .contact_form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .input {
    background-color: #fff;
  }
  
  .submit_button {
    background-color: #0066cc;
    color: white;
    padding: 0.75rem;
    font-size: 1rem;
    text-transform: none;
    width: 100%;
    max-width: 200px;
    margin: 1rem auto 0 auto;
  }
  
  .submit_button:hover {
    background-color: #004c99;
  }
  