.home_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 2rem 1rem;
}

.home_top_hero_section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 0;
  gap: 2rem;
}

.home_title {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

.home_title .name {
  font-size: 3.5rem;
  font-weight: bold;
  color: #333;
}

.home_title .description {
  margin-top: 1rem;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

.home_hero .hero {
  max-width: 80%;
}

.home_services_section {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 3rem 1rem;
  max-width: 1200px;
}

.skills {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 2rem;
  justify-items: center;
}

.skills_title {
  font-size: 2.5rem;
  text-align: center;
  padding-bottom: 1.5rem;
  color: #333;
}

.sub_title {
  font-weight: normal;
  text-align: center;
  margin-bottom: 2rem;
  color: #666;
}

.skill {
  width: 100px;
  height: auto;
  text-align: center;
}

.skill img {
  width: 100px;
  height: 60px;
  object-fit: contain;
}

.skill h4 {
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #444;
}

/* Responsive Design */
@media (max-width: 768px) {
  .home_top_hero_section {
    flex-direction: column;
  }

  .home_title .name {
    font-size: 2.5rem;
  }

  .home_hero .hero {
    max-width: 100%;
  }

  .skills {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
}

@media (max-width: 480px) {
  .home_title .name {
    font-size: 2rem;
  }

  .skills {
    grid-template-columns: repeat(2, 1fr);
  }
}
