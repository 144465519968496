.projects_container {
  padding: 2rem;
  background-color: #f9f9f9;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

/* Title Styling */
.title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 2rem !important;
  text-align: center;
  color: #333;
}

/* Search Field */
.searchField {
  margin-bottom: 2rem !important;
  width: 100%;
  max-width: 600px;
  font-size: 1.2rem;
}

/* Project List Grid */
.project_list {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
  width: 100%;
  max-width: 900px;
}

/* On larger screens, show two columns */
@media (min-width: 768px) {
  .project_list {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Project Item Card */
.project_item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1.5rem;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.project_item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
}

/* Thumbnail Styling */
.thumbnail {
  width: 120px !important; /* Increase thumbnail size */
  height: 120px !important;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 1.5rem;
}

/* Project Text */
.project_text {
  flex: 1;
}

.project_text h5 {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #333;
}

/* Trim description to 3 lines */
.project_text p {
  font-size: 1.1rem;
  color: #777;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
